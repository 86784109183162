@import "./variables.scss";

main {
  align-content: baseline;
  box-sizing: border-box;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  min-height: 100vh;
}

header {
  position: sticky;
  top: 0;

  /* Add the blur effect */
  // filter: blur(8px);
  // -webkit-filter: blur(8px);
  background: rgba(255, 255, 255, 0.95);

  padding: 1rem 2rem;
  max-width: 100vw;

  display: flex;
  justify-content: space-between;

  & .SignUp {
    font-size: 1rem;
    border: 1px solid #0077ff86;
    border-radius: 1em;
    padding: 0.5em;

    &:hover {
      background: #0077ff18;
    }
  }

  a {
    text-decoration: none;
    background-color: transparent;

    color: $base-color;
    padding: 1em;

    &:visited {
      color: $base-color-dark;
    }

    &:hover,
    &:active {
      background: $base-color-dark;
      color: $color-light;
    }
  }

  nav {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        position: relative;
      }
    }
  }
}

.banner {
  text-align: center;
  padding: 4rem;
  height: 100vh;

  h2 {
    font-weight: normal;
  }

  .highlight {
    display: inline;
    font-weight: bold;
  }

}
.banner > * {
  padding: 0;
  margin: 0;
  line-height: 1.2;
}

p {
  font-size: 1rem;
}

a.btn,
button {
  font-size: 1rem;
  border: 1px solid #0077ff86;

  border-radius: 0.5em;
  padding: 0.5em;
  background: white;
  padding: 0.5em;
  color: #0076ff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;

  &:hover {
    background: #0077ff18;
  }

  svg {
    position: relative;
    top: 2px;
  }
}

footer {
  padding: 2rem;
}

main {
  max-width: 50vw;
}

form {
  display: flex;
  justify-content: stretch;
  flex-direction: column;

  padding: 1rem;

  button {
    align-self: flex-end;
  }
}

.inputGroup {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  input {
    margin: 0;
    height: 2rem;
    padding: 0.25rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
